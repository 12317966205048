import React, { useContext } from "react";;
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import { StaticImage } from "gatsby-plugin-image";
import "src/scss/components/_brandLogoSection.scss";
import { BgPatternImage,Picture,AccruentImg,LocalizedLink } from "../commonComponents";
const basf = "../../images/logos/accruent-partners/Basf-logo.png";
const latricinia = "../../images/logos/accruent-partners/laricina-energy-logo.png";
const abbvie = "../../images/logos/accruent-partners/abbvie-logo.png";
const genesco = "../../images/logos/accruent-partners/genesco-logo.png";
const minnesotaTwins = "../../images/logos/accruent-partners/MinnesotaTwins_Logo.png";
const huntsmanCorp = "../../images/logos/accruent-partners/huntsman-logo.png";
const loreal = "../../images/logos/accruent-partners/loreal-logo.png";
const coop = "../../images/logos/accruent-partners/co-op-logo.png";
const zurich = "../../images/logos/accruent-partners/zurich-logo.png";
const mscm = "../../images/logos/accruent-partners/mscm-logo.png";
const totalsa = "../../images/logos/accruent-partners/total-logo.png"

const defaultCompanyLogos = [
  {
    image: <StaticImage src={basf} alt="Accruent - Solutions - Accruent Parners Logos - BASF" decoding = "async" />,
    uuid: "1101",
  },
  {
    image: <StaticImage src={latricinia} alt="Accruent - Solutions - Accruent Parners Logos - Latricini" decoding = "async" />,
    uuid: "1106",
  },
  {
    image: <StaticImage src={abbvie} alt="Accruent - Solutions - Accruent Parners Logos - AbbVie" decoding = "async" />,
    uuid: "1107",
  },
  {
    image: <StaticImage src={genesco} alt="Accruent - Solutions - Accruent Parners Logos - Genesco" decoding = "async" />,
    uuid: "1108",
  },
  {
    image: <StaticImage src={minnesotaTwins} alt="Accruent - Solutions - Accruent Parners Logos - Minnesota Twins" decoding = "async" />,
    uuid: "1109",
  },
  {
    image: <StaticImage src={huntsmanCorp} alt="Accruent - Solutions - Accruent Parners Logos - Huntsman Corp" decoding = "async" />,
    uuid: "1110",
  },
];

const ukCompanyLogos = [
  {
    image: <StaticImage src={coop} alt="Accruent - Solutions - Accruent Parners Logos - COOP" decoding = "async" />,
    uuid: "1102",
  },
  {
    image: <StaticImage src={loreal} alt="Accruent - Solutions - Accruent Parners Logos - Loreal" decoding = "async" />,
    uuid: "1100",
  },
  {
    image: <StaticImage src={zurich} alt="Accruent - Solutions - Accruent Parners Logos - Zurich Airport" decoding = "async" />,
    uuid: "1103",
  },
  {
    image: <StaticImage src={totalsa} alt="Accruent - Solutions - Accruent Parners Logos - Total SA" decoding = "async" />,
    uuid: "1104",
  },
  {
    image: <StaticImage src={basf} alt="Accruent - Solutions - Accruent Parners Logos - BASF" decoding = "async" />,
    uuid: "1101",
  },
  {
    image: <StaticImage src={mscm} alt="Accruent - Solutions - Accruent Parners Logos - MSCM" decoding = "async" />,
    uuid: "1105",
  },
];

const CompanyLogos = (
  {
    data,
    fieldTitle,
    nid,
    entityId,
    isHome = false,
    isStaticFile = false,
    isTeaser = false,
    showBackgroundPattern = false,
    fieldBody,
    locale = false,
    fieldLogoStyle = null,
    fieldShowCtaButton = false,
    fieldCtaUrl = {},
    isProductLogo = false,
  }
) => {
  const logos = locale === 'en-gb' ? ukCompanyLogos : defaultCompanyLogos;
  //To be reworked to make more scalable
  const isEMS = entityId === "48";
  // PLACEHOLDER ***
  const { pageTypeContext } = useContext(ResourcesContext);
  const checkForLandingPage = pageTypeContext && pageTypeContext === "landing_page";

  if (fieldLogoStyle && fieldLogoStyle === "logo_with_grey_bg" && checkForLandingPage) {
    return (
      <div className='content-brand-logo-section'>
        <div className='eyebrow'>{fieldTitle}</div>
        <div className='columns is-multiline'>
          {Array.isArray(data) && data?.map((item, index) => {
            return (
              <div className='column is-2-desktop is-6-mobile' key={index}>
                <div className='logo-card tw-h-[56px] tw-w-[152px] rounded-[15px] tw-bg-[#F5F5F5]'>
                  <AccruentImg
                    file={item}
                  />
                </div>
              </div>
            )
          })}

        </div>
      </div>
    )
  }

  if (!data) {
    return (
      <div className="columns">
        <div className="column is-12">
          <div className="column is-justify-content-center align-item-center companies-logos">
            {logos.map((logo) => {
              return (
                <div
                  className="logo-box column is-12-desktop is-3-mobile is-4-tablet"
                  key={logo.uuid}
                >
                  <figure className="image is-square">
                    {logo.image}
                  </figure>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  if (isHome) {
    return (
      <div className="solutions-logos-wrapper is-home">
        {fieldTitle && (
          <div className={"columns is-centered is-vcentered is-multiline is-mobile " + (showBackgroundPattern ? "logo-content-box" : "")}>
            <div className="column has-text-centered is-12 title-column tw-p-[0px_30px]">
              <h4 className="title title-4 has-text-centered">
                {fieldTitle}
              </h4>
            </div>
          </div>
        )}
        <div className="columns">
          <div className="column is-12">
            <div className="column is-justify-content-center align-item-center companies-logos tw-pt-[26px]">
              {data.map((logo, index) => {
                return (
                  <div
                    className="logo-box column is-12-desktop is-3-mobile is-4-tablet"
                    key={logo?.alt || index}
                  >
                    <figure className="image">
                      <AccruentImg file={logo} />
                    </figure>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
  else if(isProductLogo) {
    return (
      <div className="tw-flex tw-flex-wrap tw-justify-center tw-mt-4 tw-mb-[52px] tw-gap-6 md:tw-gap-0 tw-min-h-[41px] md:tw-min-h-[auto]">
        {data?.map((item, index) => {
          const lastOddClass =
            index + 1 == data?.length && index % 2 == 0 ? "last-odd-item" : "";
          return (
            <LocalizedLink className="tw-mr-0 md:tw-mr-[60px] tw-h-[25px] tw-mb-4 tw-max-w-[40%] tw-w-[40%] md:tw-w-auto md:tw-max-w-[20%]" to={item?.link}>
              <Picture
                key={index}
                image={item?.logo}
                className={`tw-max-h-[25px] tw-w-auto ${lastOddClass}`}
              />
            </LocalizedLink>
          );
        })}
      </div>
    );
  }
  else if (isStaticFile) {
    return (
      <div className="columns">
        <div className="column is-12">
          <div className="column is-justify-content-center align-item-center companies-logos">
            {data?.map((logo) => {
              return (
                <div
                  className="logo-box column is-12-desktop is-3-mobile is-4-tablet"
                  key={logo.uuid}
                >
                  <figure className="image">
                    <img src={logo.src} alt={logo.alt} decoding = "async" />
                  </figure>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`tw-mb-0 tw-product-page-logos-box ${isTeaser ? 'is-teaser tw-mb-0' : ''} ${fieldShowCtaButton ? 'has-cta tw-pl-[16px] tw-pr-[16px] md:tw-pl-0 md:tw-pr-0' : ''} ${showBackgroundPattern ? 'full-width-bg-wrapper' : ''}`}
        component="CompanyLogos"
        nid={nid}
        id={fieldTitle}
      >
        {showBackgroundPattern && <div className="bg-image-container">
          <BgPatternImage className="bg-image" >
          </BgPatternImage>
        </div>}
        {fieldTitle && (
          <div className={"tw-flex -tw-mx-3 -tw-mt-3 tw-mb-3 tw-justify-center tw-items-center tw-flex-wrap is-mobile " + (showBackgroundPattern ? "logo-content-box" : "")}>
            <div className="tw-title-column tw-is-12">
              {isTeaser ? (
                <h4
                  className={`tw-mb-6 tw-text-center tw-break-normal tw-font-Poppins tw-text-accruent-heading-color tw-text-mobileXxlFont md:tw-text-xxlFont ${isEMS ? "has-text-accruent-sapphire" : ""
                    }`}
                >
                  {fieldTitle}
                </h4>
              ) : (
                <p
                  className={`has-text-centered tw-title tw-title-company-logo ${isEMS ? "has-text-accruent-sapphire" : ""
                    }`}
                >
                  {fieldTitle}
                </p>
              )}
              {fieldBody && <p className="tw-company-logo-body">{fieldBody}</p>}
            </div>
          </div>
        )}
        <div className={`tw-company-logo-wrapper is-mobile ${showBackgroundPattern ? "logo-content-box" : ""}`}>
          {Array.isArray(data) && data?.map((logo, index) => {
            const logoImageAttr = logo?.gatsbyImage?.images?.fallback
            if (logo?.gatsbyImage) {
              return (
                <div
                  key={logo.alt || index}
                  className={"tw-company-logo tw-logo-item"}
                >
                  {/* <AccruentImg file={logo} /> */}
                  <img
                    {...logoImageAttr}
                    alt={logo?.alt}
                    loading="eager"
                    fetchpriority="high"
                    className={'tw-h-auto tw-max-w-full tw-block tw-align-middle'}
                    decoding = "async"
                  />
                </div>
              );
            } else return null;
          })}
          {fieldShowCtaButton && (
            <div
              key="cta-button"
              className="tw-company-logo cta-link cta-link-tw is-centered is-vcentered"
            >
              {fieldCtaUrl && (
                <LocalizedLink
                  to={fieldCtaUrl.url}
                  className="tw-text-accruent_sapphire tw-font-Poppins tw-text-[16px] tw-leading-[26px] tw-font-bold"
                >
                  {`${fieldCtaUrl.title} >`}
                </LocalizedLink>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default CompanyLogos;
